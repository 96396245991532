export default [
  {
    type: "selection",
    width: 38,
    align: "center",
  },
  {
    title: "Name",
    key: "Name",
    minWidth: 80,
    // sortable: true,
  },
  {
    title: "Job Title",
    key: "JobTitle",
    minWidth: 80,
    // sortable: true,
  },
  {
    title: "Email",
    key: "email",
    // sortable: true,
    minWidth: 80,
  },
  {
    title: "Phone",
    key: "Phone",
    // sortable: true,
    minWidth: 80,
  },
  {
    title: "Education",
    key: "Education",
    // sortable: true,
    minWidth: 80,
  },
   {
    title: "Session Mode",
    key: "SessionMode",
    // sortable: true,
    minWidth: 80,
  },
  {
    title: "Profile Summary",
    key: "ProfileSummary",
    // sortable: true,
    minWidth: 80,
  },
];
